<template>
    <v-menu
        content-class="measurements-tooltip bg-white text-on-surface pa-5 elevation-3"
        location="bottom"
        activator="parent"
        open-on-hover
        allow-overflow
        persistent
        offset="26">
        <slot></slot>
    </v-menu>
</template>

<script setup lang="ts">
</script>

<style lang="scss">

    .measurements-tooltip {
        transform: translateX(-50%) !important;
        position: absolute !important;
        border-radius: 4px;
        box-shadow: 0px 2px 5.2px 0px rgba(0, 0, 0, 0.10) !important;
        width: auto !important;
        max-width: 300px !important;
        white-space: normal !important;
        word-wrap: break-word !important;
    }

    .tooltip-link {
        color: darkblue;
        text-decoration: underline;
        cursor: pointer;
    }
</style>