<template>
    <div class="app-planner-stem-input-group">
        <div class="pa-1" v-if="!store.recommendedStems.length">
            <span inline class="font-weight-bold text-caution d-flex align-center">
                <v-icon class="mr-2" size="25" color="caution">mdi-alert</v-icon>
                No Recommended Stems
            </span>
            <app-tooltip activator="parent" location="bottom" width="300" class="">
                <div class="pa-2">
                    <p>
                        <v-icon size="25" color="caution">mdi-alert</v-icon>
                        Select a stem from the full list
                    </p>
                    <br />
                    <p>
                        No stems could be recommended. This may be due to the leg length and offset
                        targets, inadequate bone contact, and/or unsuitable neck-cuts.
                    </p>
                </div>
            </app-tooltip>
        </div>
        <app-toggle
            v-model="mode"
            :options="[
                {
                    label: 'Recommended',
                    value: 'recommended',
                    disabled: store.recommendedStems.length === 0,
                },
                { label: 'Other', value: 'other' },
            ]"
        />
    </div>

    <div v-if="mode === 'recommended'">
        <v-select
            label="Current Stem"
            v-model="store.selectedStem"
            :items="store.recommendedStems"
            item-title="name"
            item-value="stemUrl"
            variant="underlined"
            hide-details
        />
    </div>
    <div v-else-if="mode === 'other'">
        <Field name="stem-selected-type" v-slot="{ field }">
            <v-select
                v-bind="field"
                label="Type"
                v-model="store.selectedType"
                :items="store.selectableTypes"
                item-title="name"
                item-value="type"
                variant="underlined"
                hide-details
            />
        </Field>
        <br />
        <Field
            name="stem-selected-size"
            :rules="{ required: true }"
            v-slot="{ field, errors }"
            validate-on-blur
            v-model="store.selectedSize"
        >
            <v-select
                label="Size"
                v-bind="field"
                v-model="store.selectedSize"
                :items="sizeItems"
                item-title="size"
                item-value="size"
                variant="underlined"
                :error-messages="errors.length ? 'Please select a size for the new stem' : ''"
            >
                <template v-slot:item="{ props, item }">
                    <v-list-subheader v-if="item.value?.header">
                        {{ item.value.header }}
                    </v-list-subheader>
                    <v-divider v-else-if="item.value?.divider" />
                    <v-list-item v-else v-bind="props">
                        <template v-slot:prepend>
                            <v-icon
                                v-if="(item.raw as unknown as SelectableStem)?.suitable"
                                color="green"
                            >
                                mdi-check-bold
                            </v-icon>
                            <v-icon v-else color="red">mdi-alert-outline</v-icon>
                        </template>
                        {{ props.size }}
                    </v-list-item>
                </template>
            </v-select>
        </Field>
    </div>

    <app-hint class="mt-4">
        <app-text inline>Changing Stem selection will reset manual stem placement</app-text>
        <app-tooltip activator="parent" location="bottom" width="300">
            <p class="font-weight-bold">
                Changing your Stem selection will reset manual positioning adjustments
            </p>
            <br />
            <p>
                Each Stem has an automatic placement generated during case processing. Changing the
                size or type of Stem will place the new Stem at its automatic placement.
            </p>
        </app-tooltip>
    </app-hint>
</template>

<style scoped>
    .app-planner-stem-input-group {
        margin-bottom: 10px;
    }

    .app-stem-selection-reset-hint {
        margin-top: 20px;
    }
</style>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import AppText from '@/components/AppText.vue';
    import { type SelectableStem, useStemOverlayStore } from '@/planner/stemOverlayStore';
    import { watchImmediate } from '@vueuse/core';
    import { compact } from 'lodash';
    import { Field } from 'vee-validate';

    const store = useStemOverlayStore();

    const mode = ref<'recommended' | 'other' | null>(null);

    // When the selected stem is changed the mode should be updated according to whether
    // it is recommended or not
    watchImmediate(
        () => store.isSelectedStemRecommended,
        (value) => {
            mode.value = value === null ? null : value ? 'recommended' : 'other';
        },
    );

    watchImmediate(mode, (m) => {
        if (m === 'other') {
            // When selection mode to is changed to 'other' initialize the selectable type and
            // size from the current selected stem
            store.initializeTypeAndSize();
        } else {
            // Clearing the selected type and size when the mode is changed to recommended
            store.selectedType = null;
            store.selectedSize = null;

            // When mode is recommended change the stem to a recommended one
            if (!store.isSelectedStemRecommended) {
                store.selectedStem = store.recommendedStems[0].stemUrl;
            }
        }
    });

    const sizeItems = computed(() => {
        const suitableStems = store.selectableSizes.filter((stem) => stem.suitable);
        const unsuitableStems = store.selectableSizes.filter((stem) => !stem.suitable);

        // create the size options list
        return compact([
            // add the "Suggested Sizes" header in the list
            suitableStems.length > 0 ? { header: 'Suggested Sizes' } : null,
            // add the suitable stem items
            ...suitableStems,
            // add a divider in the select items list
            suitableStems.length > 0 && unsuitableStems.length > 0 ? { divider: true } : null,
            // add the "Other sizes" header in the list
            unsuitableStems.length > 0 ? { header: 'Other Sizes' } : null,
            // add all other stem items
            ...unsuitableStems,
        ]);
    });
</script>
